import Vue from "vue";
import VueRouter from "vue-router";
import LoginComponent from "../views/LoginComponent.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginComponent,
    props: true,
  },
  {
    path: "/dashboard/:standort_id",
    name: "dashboard",
    component: () => import("../views/DashboardComponent.vue"),
  },
  {
    path: "/paketauswahl/:standort_id",
    name: "paketauswahl",
    component: () => import("../views/PaketauswahlComponent.vue"),
  },
  {
    path: "/einzelleistungen/:standort_id",
    name: "einzelleistungen",
    component: () => import("../views/EinzelleistungenComponent.vue"),
  },
  {
    path: "/auswahl/:standort_id",
    name: "auswahl",
    component: () => import("../views/AuswahlComponent.vue"),
  },
  {
    path: "/success/",
    name: "success",
    component: () => import("../views/ErfolgreichGebuchComponent.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <div class="login-page wallpaper-login">
    <b-container fluid>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <div class="card login">
            <h1>Login</h1>
            <form class="form-group">
              <b-form-select
                v-model="selectedStandort"
                :options="options"
                class="mb-1 mt-3 col-12 pt-2 pb-2 custom-select"
              >
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    -- Bitte Standort wählen --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-input
                v-model="password"
                class="form-control"
                type="password"
                placeholder="Passwort eingeben"
              ></b-form-input>
              <b-button type="submit" @click="login" variant="outline-primary"
                >Anmelden</b-button
              >
              <b-alert
                v-model="errored"
                class="errored"
                variant="danger"
                dismissible
              >
                <!--Fehler: {{error}}-->
                Fehler:<br />Falsches Passwort. Bitte erneut versuchen.
              </b-alert>
            </form>
          </div>
          <div class="card" style="margin-top: 30px">
            <FooterComponent />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { loginUser, getAllStandorte } from "@/client";
import FooterComponent from "@/components/FooterComponent";

export default {
  name: "LoginView",
  components: {
    FooterComponent,
  },
  data() {
    return {
      password: "",
      errored: false,
      error: null,
      data: null,
      selectedStandort: null,
      options: [
        //{ value: "A", text: "Standort A" },
        //{ value: "B", text: "Standort B" },
      ],
    };
  },
  mounted() {
    getAllStandorte((data, error) => {
      if (error) {
        this.errored = true;
        this.error = error;
      }
      if (data) {
        this.data = data; //.substring(1);
        //console.log(this.data[0].standort_name);
        this.setStandorte();
      }
    });
  },
  methods: {
    setStandorte() {
      this.options = [];
      for (var data of this.data) {
        console.log(data.standort_id);
        this.options.push({
          value: data.a_benutzername,
          text: data.standort_name,
        });
      }
      //this.options.push({ value: "standort_name", text: "a_benutzername" });
    },
    login() {
      loginUser(this.selectedStandort, this.password, (sessionToken, error) => {
        if (error) {
          this.errored = true;
          this.error = error;
        }
        if (sessionToken) {
          this.$store.dispatch("setUserToken", sessionToken.substring(1));
          this.$store.dispatch("setUserId", this.dealId);
          this.$router.push("/dashboard/1");
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1rem;
}

.card {
  padding: 20px;
}

.form-group {
  margin-bottom: unset;
}

.form-group button {
  width: 100%;
}

.form-group input {
  margin-top: 20px;
  margin-bottom: 20px;
}

.wallpaper-login {
  background: url("../assets/pexels-stephan-seeber-1261728_blur.jpg") no-repeat
    center center;
  background-size: cover;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}

.form-group div[role="alert"] {
  margin-top: 20px;
}

.errored {
  animation-name: errorShake;
  animation-duration: 0.4s;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

@keyframes errorShake {
  0% {
    transform: translateX(-25px);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}
.btn-outline-primary {
  border-color: #ec5500 !important;
  color: #ec5500 !important;
}

.btn-outline-primary:hover {
  background-color: #ec5500 !important;
  color: #fff !important;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
</style>

/* eslint-disable */
import axios from 'axios';
const FormData = require('form-data');
axios.defaults.timeout = 1200 * 1000;

const mockError = "Beispielfehler!"

const protocol = "https://"
const host = "crazy-mclaren.82-165-176-243.plesk.page/"

const storage = window.sessionStorage;
let userSessionToken = storage.getItem('userSessionToken');

function getAllPakete2(callback) {
    const route = 'get_pakete.php'
    const url = protocol+host+route+'?'

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {

        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
      .catch((error) => {
          // handle error
          console.log(error)
          callback(null, error)
      })
}

function getAllPakete(callback) {
    const route = 'get_pakete.php'
    const formData = new FormData();
    console.log("darko");
    axios.post(protocol+host+route, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        callback(response.data, null);
        //console.log(response);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function getAllEinzelleistungen(callback) {
    const route = 'get_einzelleistungen.php'
    const formData = new FormData();
    axios.post(protocol+host+route, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        callback(response.data, null);
        //console.log(response);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function getAllStandorte(callback) {
    const route = 'get_all_standorte.php'
    const formData = new FormData();
    axios.post(protocol+host+route, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        callback(response.data, null);
        console.log(response);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function getTermine(standort_id, datum, callback) {
    const route = 'get_termine.php'
    const formData = new FormData();
    console.log(datum);
    formData.append("standort_id", standort_id);
    formData.append("datum", datum);

    axios.post(protocol+host+route, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + userSessionToken,
        },
    }).then((response) => {
        callback(response.data, null);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function startTermin(kunde_id, callback) {
    const route = 'terminStarten.php'
    const formData = new FormData();
    formData.append("kunde_id", kunde_id);

    axios.post(protocol+host+route, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + userSessionToken,
        },
    }).then((response) => {
        callback(response.data, null);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function bezahlenTermin(kunde_id, callback) {
    const route = 'terminBezahlen.php'
    const formData = new FormData();
    formData.append("kunde_id", kunde_id);

    axios.post(protocol+host+route, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + userSessionToken,
        },
    }).then((response) => {
        callback(response.data, null);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function loginUser(selectedStandort, password, callback) {
    const route = 'session.php'

    console.log('UserID: ' + selectedStandort)

    const credentials = selectedStandort + ':' + password;
    const headerValue = window.btoa(unescape(encodeURIComponent(credentials)));
    const completeHeader = 'Basic ' + headerValue;

    axios.get(protocol+host+route, {
            headers: {
                    'Authorization': completeHeader,
                    'Access-Control-Allow-Headers': "*",
            }
    }).then((response) => {
            callback(response.data, null);
            const sessionToken = response.data.substring(1);
            storage.setItem('userSessionToken', sessionToken);
            userSessionToken = storage.getItem('userSessionToken');
            storage.setItem('userSessionToken', sessionToken);
    }).catch((error) => {
            callback(null, error)
            console.log(error);
    })
}

function setPW(a_benutzername, a_hash, callback) {
    const route = 'setpw.php'
    const formData = new FormData();

    formData.append("a_benutzername", a_benutzername);
    formData.append("a_hash", a_hash);

    axios.post(protocol+host+route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        callback(response.data, null);
        console.log(response);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function getUrls(id, callback) {
    const route = 'get_urls2.php'
    const url = protocol+host+route+'?'+id

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

function resetPW(id, id2, callback) {
    const route = 'resetpw.php'
    const url = protocol+host+route+'?'+id+id2
    console.log(url)

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id,
          id2: id2
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

function addInfluencer(id, influencer_name, callback) {
    const route = 'add_influencer.php'
    const url = protocol+host+route
    console.log(url)

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id,
          influencer_name: influencer_name
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
        window.location.reload()
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

function addTerminPaket(standort_id, customer, selectedEinzelleistungen, callback) {
    const route = "addTermin.php";
    const formData = new FormData();
    // console.log(standort_id, customer)
    formData.append("standort_id", standort_id);
    formData.append("kunde_name", customer.name);
    formData.append("kunde_paket_id", customer.kunde_paket_id);

    let einzelleistungenIds = "";
    selectedEinzelleistungen.forEach((einzelleistung, index) => {
        einzelleistungenIds += einzelleistung.einzelleistung_id;
        if (index < selectedEinzelleistungen.length - 1) {
            einzelleistungenIds += ',';
        }
    });

    formData.append("kunde_einzelleistungen_ids", einzelleistungenIds);
    console.log(standort_id, customer, selectedEinzelleistungen, einzelleistungenIds);

    axios.post(protocol + host + route, formData, {
          headers: formData.getHeaders
      }).then((response) => {
        callback(response, null);
    }).catch((error) => {
        callback(null, error);
    })
}

export {
        loginUser, getAllStandorte, getAllPakete, getAllEinzelleistungen, addTerminPaket, getTermine, startTermin, bezahlenTermin
}
